var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('premium-banner',{attrs:{"restrictedFeaturePhrase":"continue using Optimizations with AI"},model:{value:(_vm.showPremiumBanner),callback:function ($$v) {_vm.showPremiumBanner=$$v},expression:"showPremiumBanner"}}),_c('r-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAiOptimizationInProg()),expression:"showAiOptimizationInProg()"}]},[_c('v-card-text',{staticClass:"text--primary text-body-1 pt-4 pb-3 text-center"},[_c('h3',{staticClass:"pt-2"},[_vm._v("Optimizing...")]),_c('v-img',{staticClass:"mx-auto",attrs:{"aspect-ratio":"1","width":"50","max-width":"50","src":require("@/assets/logos/logo_blue_1.png"),"contain":""}}),_c('h3',{staticClass:"text-body-1 font-italic text--secondary"},[_vm._v(" This might take a few seconds to complete ")])],1),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'},[_c('v-btn',{attrs:{"outlined":"","color":"gray","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAiOptimizationError()),expression:"showAiOptimizationError()"}]},[_c('v-card-text',{staticClass:"text--primary text-body-1 pt-4 pb-3 text-center"},[_c('h3',{staticClass:"pt-2"},[_vm._v("Oops. Something went wrong.")]),_c('h3',{staticClass:"text-body-1 font-italic text--secondary"},[_vm._v(" Please try again in a few minutes. ")])]),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'},[_c('v-btn',{attrs:{"outlined":"","color":"gray","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAiOptimizedEditablePhrasing()),expression:"showAiOptimizedEditablePhrasing()"}]},[_c('v-card-text',{staticClass:"text--primary text-body-1 pt-4 pb-3"},[_c('h3',{staticClass:"pt-2"},[_vm._v("Optimized Phrasing")]),_c('h3',{staticClass:"text-body-1 font-italic text--secondary"},[_vm._v(" Make edits below ")]),_c('v-textarea',{attrs:{"rows":5},model:{value:(_vm.aiOptimizedPhrasing),callback:function ($$v) {_vm.aiOptimizedPhrasing=$$v},expression:"aiOptimizedPhrasing"}})],1),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'},[_c('v-btn',{attrs:{"outlined":"","color":"gray","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel Changes ")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","text":""},on:{"click":function($event){return _vm.saveNewPhrasing()}}},[_vm._v(" Submit and Save ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmNewPhrasing()),expression:"showConfirmNewPhrasing()"}]},[_c('v-card-text',{staticClass:"text--primary text-body-1 pt-4 pb-3"},[_c('h4',{staticClass:"pt-2 text-center"},[_vm._v(" You are about to override your previously Saved Phrasing of ")]),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.phrasing))]),_c('h4',{staticClass:"pt-2 text-center"},[_vm._v("With the following version:")]),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.aiOptimizedPhrasing))]),_c('h4',{staticClass:"pt-2 text-center"},[_vm._v(" Do you want to override with the new version? ")])]),_c('v-card-actions',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'},[_c('v-btn',{attrs:{"color":"gray","outlined":"","text":""},on:{"click":function($event){return _vm.backToEditing()}}},[_vm._v(" No - Continue Editing ")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){return _vm.overrideWithNewPhrasing()}}},[_vm._v(" Yes - Override ")])],1)],1)]),_c('v-btn',{attrs:{"color":"primary","outlined":"","text":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.startOptimizeWithAi()}}},[_vm._v(" Optimize with AI ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }