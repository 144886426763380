<template>
  <div>
    <premium-banner
      v-model="showPremiumBanner"
      restrictedFeaturePhrase="continue using Optimizations with AI"
    />
    <r-dialog v-model="dialog">
      <div v-show="showAiOptimizationInProg()">
        <v-card-text class="text--primary text-body-1 pt-4 pb-3 text-center">
          <h3 class="pt-2">Optimizing...</h3>
          <v-img
            aspect-ratio="1"
            width="50"
            max-width="50"
            class="mx-auto"
            :src="require(`@/assets/logos/logo_blue_1.png`)"
            contain
          >
          </v-img>
          <h3 class="text-body-1 font-italic text--secondary">
            This might take a few seconds to complete
          </h3>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xsOnly
              ? 'flex-wrap justify-center'
              : 'flex-wrap justify-end'
          "
        >
          <v-btn outlined color="gray" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </div>
      <div v-show="showAiOptimizationError()">
        <v-card-text class="text--primary text-body-1 pt-4 pb-3 text-center">
          <h3 class="pt-2">Oops. Something went wrong.</h3>
          <h3 class="text-body-1 font-italic text--secondary">
            Please try again in a few minutes.
          </h3>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xsOnly
              ? 'flex-wrap justify-center'
              : 'flex-wrap justify-end'
          "
        >
          <v-btn outlined color="gray" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </div>
      <div v-show="showAiOptimizedEditablePhrasing()">
        <v-card-text class="text--primary text-body-1 pt-4 pb-3">
          <h3 class="pt-2">Optimized Phrasing</h3>
          <h3 class="text-body-1 font-italic text--secondary">
            Make edits below
          </h3>
          <v-textarea v-model="aiOptimizedPhrasing" :rows="5"></v-textarea>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xsOnly
              ? 'flex-wrap justify-center'
              : 'flex-wrap justify-end'
          "
        >
          <v-btn outlined color="gray" text @click="dialog = false">
            Cancel Changes
          </v-btn>
          <v-btn outlined color="primary" text @click="saveNewPhrasing()">
            Submit and Save
          </v-btn>
        </v-card-actions>
      </div>
      <div v-show="showConfirmNewPhrasing()">
        <v-card-text class="text--primary text-body-1 pt-4 pb-3">
          <h4 class="pt-2 text-center">
            You are about to override your previously Saved Phrasing of
          </h4>
          <p class="mt-2">{{ phrasing }}</p>
          <h4 class="pt-2 text-center">With the following version:</h4>
          <p class="mt-2">{{ aiOptimizedPhrasing }}</p>
          <h4 class="pt-2 text-center">
            Do you want to override with the new version?
          </h4>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xsOnly
              ? 'flex-wrap justify-center'
              : 'flex-wrap justify-end'
          "
        >
          <v-btn color="gray" outlined text @click="backToEditing()">
            No - Continue Editing
          </v-btn>
          <v-btn
            color="primary"
            outlined
            text
            @click="overrideWithNewPhrasing()"
          >
            Yes - Override
          </v-btn>
        </v-card-actions>
      </div>
    </r-dialog>
    <v-btn color="primary" outlined text @click="startOptimizeWithAi()" :disabled="disabled">
      Optimize with AI
    </v-btn>
  </div>
</template>
<script>
import * as log from "../utility/logging/logger";
import * as resApi from "../api/ResumeApi";
import PremiumBanner from "./PremiumBanner.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  name: "AiOptimizePhrasing",
  components: {
    "premium-banner": PremiumBanner,
  },
  props: {
    phrasing: {
      Type: String,
      default: "",
    },
    context: {
      Type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    aiOptimizationStatus: "idle",
    aiOptimizedPhrasing: "",
    showPremiumBanner: false,
  }),
  methods: {
    showAiOptimizationInProg() {
      return this.aiOptimizationStatus === "optimizing";
    },
    showAiOptimizedEditablePhrasing() {
      return this.aiOptimizationStatus === "optimization-ready";
    },
    showConfirmNewPhrasing() {
      return this.aiOptimizationStatus === "confirm-new-phrasing";
    },
    showAiOptimizationError() {
      return this.aiOptimizationStatus === "error";
    },
    enableAiOptimizeToggle() {
      return this.aiOptimizationStatus !== "optimizing";
    },
    onOptimizeWithAiToggle(value) {
      if (value) {
        log.logInformation("Enable optimization");
        this.optimizeWithAI();
      } else {
        log.logInformation("disable optimization");
      }
    },
    startOptimizeWithAi() {
      this.dialog = true;
      log.logInformation("Enable optimization");
      this.optimizeWithAI();
    },
    saveNewPhrasing() {
      this.aiOptimizationStatus = "confirm-new-phrasing";
    },
    overrideWithNewPhrasing() {
      this.aiOptimizationStatus = "idle";
      this.dialog = false;
      this.$emit("onAcceptPhrasing", this.aiOptimizedPhrasing);
    },
    backToEditing() {
      this.aiOptimizationStatus = "optimization-ready";
    },
    async optimizeWithAI() {
      this.aiOptimizationStatus = "optimizing";
      try {
        log.logInformation("Refreshing user premium and attempts status...");

        await this.refreshPremium();

        if (!this.isPremium && this.remainingAiOptimizerAttempts <= 0) {
          this.dialog = false;
          this.showPremiumBanner = true;
        } else {
          let result = await resApi.FetchAiOptimizedPhrasing({
            resumeId: this.$store.state.resumeId,
            phrasing: this.phrasing,
            context: this.context,
          });
          if (!result.success) {
            log.logError(
              "Failed to get AI optimized description",
              "optimizeWithAI"
            );
            this.aiOptimizationStatus = "error";
          } else {
            log.logInformation("AI optimization success");
            this.aiOptimizationStatus = "optimization-ready";
            this.aiOptimizedPhrasing = result.data.optimizedPhrasing;
          }
        }
      } catch (e) {
        log.logError("Failed to get AI optimized description", e);
        this.aiOptimizationStatus = "error";
      }
    },
    ...mapActions("auth", ["refreshPremium"]),
  },
  computed: {
    ...mapFields("auth", {
      isPremium: "isPremium",
      remainingAiOptimizerAttempts: "remainingAiOptimizerAttempts",
    }),
  },
};
</script>