<template>
  <page-main
    title="Additional Information"
    nextText="That's it! Review/Customize your resume."
    buttonTabIndex="4"
    eventName="nav"
    @nav="nav('/Review')"
    @save="save()"
  >
    <template v-slot:aside>
      Any additional items to add to your resume? If there's something we haven't covered, you can include it here.
      <ul>
        <li>
          Any skills you enter here will appear on your resume with your
          previously entered skills.
        </li>
        <li>Any important points you enter will also be included.</li>
        <li>
          We've generated a professional summary for you to consider, and you can make your own edits below. 
          Resumation Plus users can also use AI to optimize their summary!
        </li>
      </ul>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            title-text="Additional Information"
            label="Are there any skills we didn’t previously cover that you would like to have included on your resume?"
            :optionsList="[]"
            :selected.sync="otherSkills"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Are there any other important points about your interests and skills?"
            :optionsList="[]"
            :selected.sync="points"
            tabindex="2"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="professionalSummary"
            header="Professional Summary"
            tabindex="3"
          >
            <template #footer>
              <v-row>
                <v-col cols="12" sm="auto">
                  <ai-optimize-phrasing
                    :disabled="!professionalSummary"
                    :phrasing="professionalSummary"
                    context="professional-summary"
                    @onAcceptPhrasing="acceptAiSuggestion"
                  ></ai-optimize-phrasing>
                </v-col>
                <v-col cols="12" sm="auto">
                  <v-btn color="primary" outlined text @click="triggerGeneration">
                    Generate from Resume
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </r-textarea>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="generateDialog">
      <v-card-text>
        <h4 class="pt-2 text-center">
          This will replace your current professional summary of
        </h4>
        <p class="mt-2">{{ professionalSummary }}</p>
        <h4 class="pt-2 text-center">With the following version:</h4>
        <p class="mt-2">{{ newSummary }}</p>
        <h4 class="pt-2 text-center">
          Do you want to replace it with the new version?
        </h4>
      </v-card-text>
      <v-card-actions
        :class="
          $vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'
        "
      >
        <v-btn color="gray" outlined text @click="closeGenerateDialog()">
          No
        </v-btn>
        <v-btn color="primary" outlined text @click="updateWithGenerated()">
          Yes - Replace
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
// @ is an alias to /src
import AiOptimizePhrasing from "../components/AiOptimizePhrasing";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import ReportBuilderBase from "../reportBuilder/ReportBuilderBase";
import * as disp from "../utility/display";

export default {
  name: "FinalInfo",
  components: {
    "ai-optimize-phrasing": AiOptimizePhrasing,
  },
  data: () => ({
    builder: new ReportBuilderBase({}),
    newSummary: "",
    generateDialog: false,
  }),
  mounted() {
    if (disp.IsNullorWhitespace(this.professionalSummary)) {
      this.professionalSummary = this.generatePS();
    }
  },
  methods: {
    save() {
      this.saveFinalInfo();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    generatePS() {
      var summary = this.builder.BuildProfessionalSummary(
        this.adjectiveDisplay,
        this.careerInterestsDisplay,
        this.roleDisplay,
        this.hardSkillsTopDisplay,
        this.softSkillsDisplay,
        this.workDict
      );
      return summary;
    },
    acceptAiSuggestion(newPhrasing) {
      this.professionalSummary = newPhrasing;
    },
    triggerGeneration() {
      if (this.professionalSummary == "") {
        this.professionalSummary = this.generatePS();
      } else {
        this.newSummary = this.generatePS();
        this.generateDialog = true;
      }
    },
    closeGenerateDialog() {
      this.generateDialog = false;
    },
    updateWithGenerated() {
      this.professionalSummary = this.newSummary;
      this.generateDialog = false;
    },
    ...mapActions("finalInfo", ["saveFinalInfo"]),
  },
  computed: {
    ...mapFields("finalInfo", [
      "otherSkills",
      "points",
      "otherNotes",
      "professionalSummary",
    ]),
    ...mapFields("adjective", ["adjectiveDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("role", ["selectedRoles"]),
    ...mapFields("hardSkill", ["hardSkills"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("work", ["workDict"]),
    roleDisplay() {
      return this.builder.BuildStringList(
        this.selectedRoles?.map((r) => r.text)
      );
    },
    hardSkillsTopDisplay() {
      return this.builder.BuildStringList(
        this.hardSkills
          ?.filter((h) => h.level == undefined || h.level == 1)
          .map((h) => h.text)
      );
    },
    softSkillsDisplay() {
      return this.builder.BuildStringList(this.softSkills?.map((s) => s.text));
    },
  },
};
</script>
